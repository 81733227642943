<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          First Aid Box Information
        </h3>
      </div>
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <asom-form-field label="First Aid Box Name">
            <p class="font-semibold">
              {{ get(boxDetails, "boxName", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Primary Location Name">
            <p class="font-semibold">
              {{ get(boxDetails, "primaryLocationName", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Secondary Location Name">
            <p class="font-semibold">
              {{ get(boxDetails, "secondaryLocationName", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Verification Remarks">
            <p class="font-semibold">
              {{ get(boxDetails, "lastVerificationRemarks", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Items" class="col-span-2">
            <asom-client-table
              v-if="!isLoading"
              :columns="[
                'inventoryTypeName',
                'firstAidBoxName',
                'itemDescription',
                'amount',
                'primaryLocationName',
                'secondaryLocationName',
                'expiryDate',
              ]"
              :data="boxDetails.items"
              :filterable="false"
            >
              <template v-slot:header_inventoryTypeName>Item Type</template>
              <template v-slot:header_firstAidBoxName>Box</template>
              <template v-slot:header_itemDescription>Description</template>
              <template v-slot:header_primaryLocationName
                >Primary Location</template
              >
              <template v-slot:header_secondaryLocationName
                >Secondary Location</template
              >
              <template v-slot:secondaryLocationName="scopedSlots">
                <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
              </template>
              <template v-slot:expiryDate="scopedSlots">
                <span>{{ displayUtcDate(scopedSlots.data) }}</span>
              </template>
            </asom-client-table>
            <div v-else class="text-center">
              <asom-icon icon="spinner" class="animate-spin" />
            </div>
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getFirstAidBoxDetails } from "../../../../services/inventory.service";

export default {
  name: "ViewFirstAidBox",
  data() {
    return {
      boxDetails: {
        boxName: get(this.$route.params, "firstAidBoxName", "-"),
        primaryLocationName: get(
          this.$route.params,
          "primaryLocationName",
          "-"
        ),
        secondaryLocationName: get(
          this.$route.params,
          "secondaryLocationName",
          "-"
        ),
        lastVerificationRemarks: get(
          this.$route.params,
          "lastVerificationRemarks",
          "-"
        ),
        items: [],
      },
      error: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getBoxDetails();
  },
  methods: {
    get,
    displayUtcDate,
    async getBoxDetails() {
      this.isLoading = true;
      const result = await getFirstAidBoxDetails({
        firstAidBoxId: get(this.$route.params, "firstAidBoxId"),
      });
      if (result.success) {
        this.setFormData(result.payload);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    setFormData(data) {
      this.boxDetails.boxName = get(data, "firstAidBoxName");
      this.boxDetails.primaryLocationName = get(
        data,
        "firstAidBoxPrimaryLocationName"
      );
      this.boxDetails.secondaryLocationName = get(
        data,
        "firstAidBoxSecondaryLocationName"
      );
      this.boxDetails.items = get(data, "firstAidInventories");
      this.boxDetails.lastVerificationRemarks = get(
        data,
        "lastVerificationRemarks"
      );
    },
  },
};
</script>
